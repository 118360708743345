import React, { useEffect, useState } from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import { Report } from "entities/clients/Investements";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { createInvestmentRoi, updateInvestmentRoi } from "api/investments";

const useStyles = makeStyles((theme) => ({
  gridContainerInfo: {
    maxWidth: "90%",
    margin: "0 auto",
    marginTop: 24,

    "& .MuiOutlinedInput-root": {
      background: "#ffffff",
    },
  },
  updateBtn: { display: "block", margin: "20px auto 70px auto" },
  sectionTitle: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
  },
}));

type FormValues = {
  investAmount: number;
  investDate: string;
  investmentId: number;
  realized: boolean;
  reportDate: string;
  roiPercent: number;
};

const RoiCreateOrUpdate: React.FC<
  RouteComponentProps<{ investementId: string; id: string }>
> = ({ match }) => {
  const { t } = useTranslation("investments");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [roi, setRoi] = useState<Report>();
  const history = useHistory();
  const investemntId: number = +match.params.investementId;
  const roiId: number = +match.params.id;

  const defaultValues: FormValues = {
    investAmount: roi?.investAmount || 0,
    investDate: roi?.investDate || "",
    investmentId: investemntId,
    realized: roi?.realized || false,
    reportDate: roi?.reportDate || "",
    roiPercent: roi?.roiPercent || 0,
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onFormSubmit = async (values: Report) => {
    setIsLoading(true);
    try {
      const transformedValues = {
        ...values,
        investmentId: investemntId,
        investAmount: Number(values.investAmount),
        roiPercent: Number(values.roiPercent),
        realized: Boolean(values.realized),
        investDate: new Date(values.investDate).toISOString(),
        reportDate: new Date(values.reportDate).toISOString(),
      };

      if (roiId) await updateInvestmentRoi(roiId, transformedValues);
      else {
        await createInvestmentRoi(transformedValues);
      }
      history.push(`/investments/${investemntId}`);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (roiId) {
      resetValues();
    }
  }, [dispatch, roiId]);

  const resetValues = () => {
    let isMounted = true;

    if (isMounted) {
      reset(({
        reportDate: roi?.reportDate,
        investAmount: roi?.investAmount,
        investDate: roi?.investDate,
        investmentId: roi?.investmentId,
        realized: roi?.realized ?? false,
        roiPercent: roi?.roiPercent ?? 0,
      } as unknown) as FormValues);
    }

    return (): void => {
      isMounted = false;
    };
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Grid
          container
          spacing={1}
          direction="row"
          className={classes.gridContainerInfo}
        >
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionTitle}>
              {t("news__section_title__roi_details")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <QuidTextField
              name="investAmount"
              type="number"
              textFieldProps={{
                fullWidth: true,
              }}
              rules={{
                required: t("details__label__news_title__required") as string,
              }}
              label={t("details__label__investAmount")}
              defaultValues={defaultValues}
            />
          </Grid>
          <Grid item xs={4}>
            <QuidTextField
              name="realized"
              textFieldProps={{
                fullWidth: true,
              }}
              rules={{
                required: t("details__label__realized_required") as boolean,
              }}
              label={t("details__label__realized")}
              defaultValues={defaultValues}
            />
          </Grid>
          <Grid item xs={4}>
            <QuidTextField
              name="investDate"
              textFieldProps={{
                type: "date",
                fullWidth: true,
                InputLabelProps: { shrink: true },
              }}
              rules={{
                required: t("details__label__investDatee_required") as string,
              }}
              label={t("details__label__investDate")}
              defaultValues={defaultValues}
            />
          </Grid>
          <Grid item xs={4}>
            <QuidTextField
              name="reportDate"
              textFieldProps={{
                type: "date",
                fullWidth: true,
                InputLabelProps: { shrink: true },
              }}
              rules={{
                required: t("details__label__reportDate_required") as string,
              }}
              defaultValues={defaultValues}
              label={t("details__label__reportDate")}
            />
          </Grid>
          <Grid item xs={4}>
            <QuidTextField
              name="roiPercent"
              textFieldProps={{
                fullWidth: true,
              }}
              type="number"
              rules={{
                required: t("details__label__percent_required") as string,
              }}
              defaultValues={defaultValues}
              label={t("details__label__roiPercent")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.updateBtn}
            variant="contained"
            color="primary"
            disabled={isLoading}
            type="submit"
          >
            {t("label_button__save")}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default RoiCreateOrUpdate;
