import React, { useCallback, useEffect, useState } from "react";
import { Grid, Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { formatDate } from "utils";
import {
  InvestmentDetailResponse,
  Pagination,
} from "@portit/core/entities/Investments";
import {
  acceptInvestement,
  getInvestementsReportsBO,
  getInvestmentDetails,
  refundInvestment,
  rejectInvestement,
  stopInvestment,
} from "api/investments";
import { RouteComponentProps, useHistory } from "react-router";
import promptsSlice from "store/reducers/prompts.reducer";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import MainTemplate from "templates/MainTemplate";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { postCryptoPaymentsManagementTriggerConfirmation } from "@portit/core/api/Crypto";
import { ONE_HOUR, TABLE_PAGE_SIZE } from "shared/constants";
import { fetchCustomerDetails } from "services/customers";
import { CustomerDetails } from "entities/clients/CustomerBackofficeEntity";
import { Report, ReporttBOListResponse } from "entities/clients/Investements";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import DetailIcon from "components/atoms/icons/DetailIcon";
import ButtonButton from "components/atoms/DownloadButton";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    marginTop: "30px",
  },
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginBottom: "20px",
  },
  actionsCancel: {
    backgroundColor: "#ff0000",
  },
  actionsApprove: {
    backgroundColor: "#32CD32",
  },
  actionsView: {
    backgroundColor: "#ffff00",
  },
  actionsCreate: {
    backgroundColor: "#008000",
  },
  actionsConfirm: {
    backgroundColor: "#FF6F0F",
  },
  actionsStop: {
    backgroundColor: "#ff0000",
  },
  actionsRefund: {
    backgroundColor: "#FF6F0F",
  },
  buttonViewOpportunity: {
    width: "100%",
    display: "flex",
    marginTop: 16,
    alignItems: "center",
    flexDirection: "column",
  },
  detailIconContainer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  investmentsContainer: {
    width: "100%",
    marginTop: 30,
    minHeight: 500,
  },
  redStatus: {
    color: "#ff0000",
  },
  csvBtn: {
    height: "100%",
    minHeight: "54px",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
}));

const InvestmentDetails: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const { t } = useTranslation("investments");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const investemntId: number = +match.params.id;
  const [pagination, setPagination] = useState({} as Pagination);
  const [page, setPage] = useState(0);
  const [
    investementDetails,
    setInvestementDetails,
  ] = useState<InvestmentDetailResponse>();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
  const investmentStatus = investementDetails?.status?.toLowerCase();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([] as Report[]);

  const getInvestementDetails = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const customerDetails = await getInvestmentDetails(investemntId);
      if (customerDetails) {
        setInvestementDetails(customerDetails);
      }
      const res = await getInvestementsReportsBO({
        investmentId: investemntId,
        page: 0,
        size: TABLE_PAGE_SIZE,
      });
      setReports(res?.reports);
      setPagination(res.pagination);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;

      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  }, [setLoading, setInvestementDetails]);

  const confirmOpportunity = async () => {
    try {
      if (investementDetails?.transactionHash && investementDetails?.currency) {
        await postCryptoPaymentsManagementTriggerConfirmation(
          investementDetails?.transactionHash,
          investementDetails.currency
        );

        dispatch(
          promptsSlice.actions.openSnackbar({
            message: t("snackbar__message__entity_confirmed"),
            type: "success",
          })
        );
      }
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const getCustomerDetails = useCallback(
    async (id: number | string): Promise<void> => {
      try {
        setLoading(true);
        const customerDetails = await fetchCustomerDetails({
          customerId: id as number,
        });
        setCustomerDetails(customerDetails);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setCustomerDetails]
  );

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const columns: ColDef[] = [
    {
      flex: 1,
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      flex: 1,
      field: "investmentId",
      headerName: t("listTable__header_name__investmentId"),
    },
    {
      flex: 1,
      field: "investAmount",
      headerName: t("listTable__header_name__investAmount"),
    },
    {
      flex: 1,
      field: "roiPercent",
      headerName: t("listTable__header_name__roiPercent"),
    },

    {
      flex: 1,
      field: "realized",
      headerName: t("listTable__header_name__realized"),
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => {
        return (
          <>
            <div
              className={classes.detailIconContainer}
              onClick={() =>
                history.push({
                  pathname: `/investments/${investemntId}/roi/${params?.row?.id}`,
                })
              }
            >
              <DetailIcon />
            </div>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (investemntId) {
      void getInvestementDetails();
    }
  }, [investemntId]);

  useEffect(() => {
    if (investementDetails?.partyId) {
      void getCustomerDetails(investementDetails.partyId);
    }
  }, [investementDetails?.partyId]);

  const investmentDetailsToPrint: InvestmentDetailResponse =
    investementDetails?.currency === "USDT" ||
    investementDetails?.currency === "BTC" ||
    investementDetails?.currency === "SBB"
      ? {
          tokenPrice: investementDetails?.tokenPrice
            ? investementDetails?.tokenPrice === "0E-18"
              ? "0.00000000000000000"
              : investementDetails?.tokenPrice
            : "n/a",
          calculatedPrice: investementDetails?.calculatedPrice
            ? investementDetails?.calculatedPrice === "0E-18"
              ? "0.00000000000000000"
              : investementDetails?.calculatedPrice
            : "n/a",
          feeAmount: investementDetails?.feeAmount
            ? investementDetails?.feeAmount === "0E-18"
              ? "0.00000000000000000"
              : investementDetails?.feeAmount
            : "n/a",
          totalPrice: investementDetails?.totalPrice
            ? investementDetails?.totalPrice === "0E-18"
              ? "0.00000000000000000"
              : investementDetails?.totalPrice
            : "n/a",
          walletId: investementDetails?.walletId || "n/a",
          lastUpdated:
            formatDate(
              investementDetails?.lastUpdated,
              "dd/MM/yyyy HH:mm:ss"
            ) || "n/a",
          reference: investementDetails?.reference || "n/a",
          id: investementDetails?.id || 0,
          partyId: investementDetails?.partyId || 0,
          date: investementDetails?.date
            ? format(new Date(investementDetails?.date), "dd/MM/yyyy HH:mm:ss")
            : "",
          status: investementDetails?.status,
          investmentType: investementDetails?.investmentType,
          noToken: investementDetails?.noToken || 0,
          currency: investementDetails?.currency,
          agreedTandC: false,
          investedAmount: investementDetails?.investedAmount,
          totalAmountInvested: investementDetails?.totalAmountInvested,
        }
      : {
          tokenPrice: investementDetails?.tokenPrice
            ? investementDetails?.tokenPrice === "0E-18"
              ? "0.00"
              : investementDetails?.tokenPrice.slice(0, -16)
            : "n/a",
          calculatedPrice: investementDetails?.calculatedPrice
            ? investementDetails?.calculatedPrice === "0E-18"
              ? "0.00"
              : investementDetails?.calculatedPrice.slice(0, -16)
            : "n/a",
          feeAmount: investementDetails?.feeAmount
            ? investementDetails?.feeAmount === "0E-18"
              ? "0.00"
              : investementDetails?.feeAmount.slice(0, -16)
            : "n/a",
          totalPrice: investementDetails?.totalPrice
            ? investementDetails?.totalPrice === "0E-18"
              ? "0.00"
              : investementDetails?.totalPrice.slice(0, -16)
            : "n/a",
          walletId: investementDetails?.walletId || "n/a",
          lastUpdated:
            formatDate(
              investementDetails?.lastUpdated,
              "dd/MM/yyyy HH:mm:ss"
            ) || "n/a",
          reference: investementDetails?.reference || "n/a",
          id: investementDetails?.id || 0,
          partyId: investementDetails?.partyId || 0,
          date: investementDetails?.date
            ? format(new Date(investementDetails?.date), "dd/MM/yyyy HH:mm:ss")
            : "",
          status: investementDetails?.status ?? "Pending",
          investmentType: investementDetails?.investmentType ?? "Buy",
          noToken: investementDetails?.noToken || 0,
          currency: investementDetails?.currency ?? "EUR",
          agreedTandC: false,
          investedAmount: investementDetails?.investedAmount,
          totalAmountInvested: investementDetails?.totalAmountInvested,
        };

  const opportunityDetailsToPrint: any = {
    name: investementDetails?.opportunity?.name,
    status: investementDetails?.opportunity?.status,
    currency: investementDetails?.opportunity?.currency,
    referralPercentage: `${investementDetails?.opportunity?.referralPercentage}%`,
  };

  const opportunitycustomerDetailsToPrint: any = {
    id: customerDetails?.customer?.id,
    firstname: customerDetails?.customer?.name,
    lastname: customerDetails?.customer?.surname,
    type: customerDetails?.customer?.type,
  };

  const opportunitycustomerbalanceDetailsToPrint: any = {
    walletId: investementDetails?.walletId,
  };

  const handleOnClick = async (type: string) => {
    try {
      setButtonClicked(true);
      if (type === "cancel") {
        await rejectInvestement(investmentDetailsToPrint.id);
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: "Investement rejected",
            type: "error",
          })
        );
      } else if (
        type === "accept" &&
        process.env.REACT_APP_PROJECT !== "elviria"
      ) {
        await acceptInvestement(investmentDetailsToPrint.id);
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: "Investement accepted",
            type: "success",
          })
        );
      } else if (type === "stop") {
        await stopInvestment(investmentDetailsToPrint.id);
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: "Investement stopped",
            type: "success",
          })
        );
      } else if (type === "refund") {
        await refundInvestment(investmentDetailsToPrint.id);
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: "Investement refunded",
            type: "success",
          })
        );
      } else {
        return null;
      }
      setButtonClicked(false);
    } catch (err: any) {
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: err?.error?.response?.investementDetails?.message,
          type: "error",
        })
      );
    }
  };

  const isConfirmButtonVisible = (params: any) => {
    if (params?.status && params?.date) {
      const isPendingBlockchain =
        params?.status?.toLowerCase() === "pending_blockchain";

      const currentDate = new Date().toISOString().slice(0, 10);
      const investmentDate = new Date(params?.date).toISOString().slice(0, 10);

      const currentTime = new Date().getTime();
      const investmentTime = new Date(params?.date).getTime();

      return (
        (isPendingBlockchain &&
          investmentDate === currentDate &&
          investmentTime - currentTime < ONE_HOUR) ||
        (isPendingBlockchain && investmentDate !== currentDate)
      );
    }
  };

  const viewOpportunity = () => {
    if (investementDetails?.opportunity?.id) {
      history.push(`/opportunities/${investementDetails?.opportunity?.id}`);
    }
  };

  return (
    <MainTemplate>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt35}>
            <Typography variant="h1" className={classes.title}>
              {t("modal__title__investment_details")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          className={classes.mt20}
        >
          <Grid item xs={3}>
            <DetailsBlock
              label="details_title"
              toPrint={investmentDetailsToPrint}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <DetailsBlock
              label="details_investment_opportunity"
              toPrint={opportunityDetailsToPrint}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <DetailsBlock
              label="details_investment_customer"
              toPrint={opportunitycustomerDetailsToPrint}
            />
            <DetailsBlock
              label="details_investment_customer_balance"
              toPrint={opportunitycustomerbalanceDetailsToPrint}
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          {investmentStatus !== "pending_blockchain" &&
            investmentStatus !== "Cancelled" &&
            investmentStatus !== "processed" &&
            investmentStatus !== "done" &&
            !buttonClicked && (
              <>
                {process.env.REACT_APP_PROJECT !== "elviria" && (
                  <Button
                    variant="contained"
                    className={classes.actionsApprove}
                    onClick={() => handleOnClick("accept")}
                  >
                    {t("button_accept")}
                  </Button>
                )}
                <Button
                  variant="contained"
                  className={classes.actionsCancel}
                  onClick={() => handleOnClick("cancel")}
                >
                  {t("button_cancel")}
                </Button>
              </>
            )}
          <Button
            variant="contained"
            className={classes.actionsView}
            onClick={viewOpportunity}
          >
            {t("button_view_opportunity")}
          </Button>
          {isConfirmButtonVisible(investementDetails) && (
            <Button
              variant="contained"
              className={classes.actionsConfirm}
              onClick={confirmOpportunity}
            >
              {t("button_try_to_confirm")}
            </Button>
          )}
          {investmentStatus === "done" &&
            investementDetails?.opportunity?.opportunitySetup?.type ===
              "LOCKED_STREAMABLE_TYPE" && (
              <>
                <Button
                  variant="contained"
                  className={classes.actionsStop}
                  onClick={() => handleOnClick("stop")}
                >
                  {t("button_stop")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.actionsRefund}
                  onClick={() => handleOnClick("refund")}
                >
                  {t("button_refund")}
                </Button>
              </>
            )}
        </div>
        <div className={classes.investmentsContainer}>
          <Button
            variant="contained"
            className={classes.actionsCreate}
            onClick={() => history.push(`/investments/${investemntId}/roi`)}
          >
            {t("button_new_roi")}
          </Button>
          {reports && (
            <QuidDataGrid
              disableSelectionOnClick
              onPageChange={onPageChange}
              sortModel={[{ field: "id", sort: "desc" }]}
              loading={loading}
              columns={columns}
              rows={reports ?? []}
              rowCount={pagination?.total_entries}
            />
          )}
        </div>
      </div>
    </MainTemplate>
  );
};

export default InvestmentDetails;
